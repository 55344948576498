<script>
export default {
  methods: {
    setDate () {
      if (!this.$store.state.auth.ngos.length) {
        return null;
      }

      let nextAvailableDay = this.$moment();
      while (!this.filters.date) {
        if (this.$store.state.auth.ngos[0].activeDow.includes(this.$moment(nextAvailableDay).day())) {
          this.filters.date = nextAvailableDay.format('YYYY-MM-DD');
        } else {
          nextAvailableDay = nextAvailableDay.add(1, 'd');
        }
      }
    }
  },
  watch: {
    '$store.state.auth.ngos': 'setDate'
  }
};
</script>

<style scoped>

</style>

<template>
  <ul class="pieces">
    <li class="label">{{ $t("pieces.pcs") }}</li>
    <li v-for="(piece, index) in pieces" :key="index">
      <img :src="piece.image">
      <span>{{ piece.text }}</span>
    </li>
  </ul>
</template>

<script>
import i18n from '../locales/i18n';
export default {
  data: () => ({
    pieces: [
      {
        image: '/images/pin-green.svg',
        text: '31+'
      },
      {
        image: '/images/pin-light-green.svg',
        text: '21-30'
      },
      {
        image: '/images/pin-orange.svg',
        text: '1-20'
      },
      {
        image: '/images/pin-blue.svg',
        text: i18n.t('runcard.none')
      }
    ]
  })
};
</script>

<template>
  <div class="step-layout">
    <div class="add-top-layer">
      <v-progress-linear
        value="50"
        background-color="#F8DAC6"
        height="5"
        color="#93D4D6" />
      <div class="step-info">
        <div class="text">
          <h3 class="title">
            <span>{{ $t("claim.claim") }}</span>
            <span class="color">{{ $t("claim.run") }}</span>
          </h3>
          <p class="sub-title">{{ $t("claim.tap") }}  <strong>{{ $t("claim.add") }} </strong>{{ $t("claim.select") }} </p>
        </div>
      </div>
      <div class="pick-time-layer">
        <div class="time-title">{{ $t("slider.times") }}</div>
        <div class="pick-time-block">
          <v-range-slider
            v-model="pickupTime"
            @change="pickupTimeChanged"
            :tick-labels="pickupTimes"
            :min="0"
            :max="8"
            thumb-label
            height="20"
            thumb-color="#0A3F60"
            color="#0A3F60"
            hide-details
            track-color="#FF8D71"
            class="align-center"
          >
            <template v-slot:prepend>
              <span style="width: 38px" class="time">
                {{pickupTimes[0]}}pm
              </span>
            </template>
            <template v-slot:thumb-label="props">
              <!-- <v-icon dark> -->
              {{ pickupTimes[props.value] }}pm
              <!-- </v-icon> -->
            </template>
            <template v-slot:append>
              <span style="width: 38px" class="time">
                {{pickupTimes[pickupTimes.length - 1]}}pm
              </span>
            </template>
          </v-range-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step2',
  data: () => ({
    min: 6,
    max: 10,
    slider: 0.5,
    pickupTimes: [
      '6:00',
      '7:00',
      '7:30',
      '8:00',
      '8:30',
      '9:00',
      '9:30',
      '10:00'
    ],
    pickupTimesFormatted: [
      '18:00',
      '19:00',
      '19:30',
      '20:00',
      '20:30',
      '21:00',
      '21:30',
      '22:00'
    ],
    pickupTime: [0, 7]
  }),
  props: ['filters'],
  methods: {
    pickupTimeChanged ($e) {
      this.filters.minPickUp = this.pickupTimesFormatted[$e[0]];
      this.filters.maxPickUp = this.pickupTimesFormatted[$e[1]];
    }
  }
};
</script>

<style>
  .pick-time-block .v-slider__tick {
    display: none !important;
  }
</style>

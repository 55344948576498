<template>
  <div class="step-layout">
    <div class="add-top-layer">
      <v-progress-linear
        value="25"
        background-color="#F8DAC6"
        height="5"
        color="#93D4D6" />
      <div class="step-info">
        <div class="text">
          <h3 class="title">
            <span>{{ $t("rundate.date") }}</span>
            <span class="color">{{ $t("rundate.torun") }}</span>
          </h3>
          <p class="sub-title map">{{ $t("rundate.available") }}</p>
        </div>
      </div>
      <div class="datepicker-block select-backdrop">
        <v-text-field
          class="light-primary-select"
          solo
          flat
          background-color="#FBE8DC"
          @focus="modal = true"
          hide-details
          readonly
        >
          <template slot="label">
            {{ $d($moment(currentDate), 'long') }}
          </template>
          <template slot="prepend-inner">
            {{ $t("rundate.pickup") }}
          </template>
          <template slot="append">
            <div class="img-wrap">
              <img src="/images/date-icon.svg">
            </div>
          </template>
        </v-text-field>
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="filters.date"
          title-date-format="ddd, D MMM YYYY"
          header-color="#FCF1EA"
          width="290px"
        >
          <!-- <template v-slot:activator="{ on }">
            <div class="activator-inner">
              <div class="prepend-inner">
                Pick up on
              </div>
              <v-text-field
                :value="formattedDate"
                readonly
                v-on="on"
              ></v-text-field>
              <div class="img-wrap">
                <img src="/images/date-icon.svg">
              </div>
            </div>
          </template> -->
          <!-- <v-date-picker v-model="date" scrollable color="light_primary"> -->
          <v-date-picker
            event-color="secondary"
            locale="i18n.locale"
            color="#FBE8DC"
            v-model="date"
            scrollable
            selected-items-text="secondary"
            class="custom-date"
            :allowed-dates="allowedDates"
          >
            <v-spacer></v-spacer>
            <v-btn text color="brown" @click="modal = false"> {{ $t("rundate.cancel") }}</v-btn>
            <v-btn text color="brown" @click="$refs.dialog.save(date)"> {{ $t("rundate.ok") }}</v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step1',
  data: () => ({
    modal: false,
    date: null
  }),
  props: ['filters'],
  computed: {
    currentDate () {
      if (this.filters.date) {
        return this.filters.date;
      }
      return this.$moment().format('YYYY-MM-DD');
    }
  },
  methods: {
    allowedDates (date) {
      return this.$store.state.auth.ngos[0].activeDow
        .includes(this.$moment(date).day()) && date >= this.$moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    currentDate () {
      this.date = this.currentDate;
    }
  }
};
</script>

<template>
  <v-btn @click.prevent="switchLocale" class='locale-changer'>
    <img :src="`/images/language.png`" class='flag'>
    <span class="flagText">{{ flagText }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'locale-changer',
  computed: {
    flagText () {
      return this.$i18n.locale === 'en' ? 'ENG' : '中文';
    }
  },
  methods: {
    switchLocale () {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'zh';
        localStorage.locale = 'zh';
        location.reload();
      } else {
        this.$i18n.locale = 'en';
        localStorage.locale = 'en';
        location.reload();
      }
    }
  }
};
</script>

<template>
  <div class="selector-backdrop">
    <v-btn @click="modal = true">
      <img class="time-logo" src="/images/date-icon.svg">
      <div class="formattedDate">{{ $d($moment(currentDate), 'damo') }}</div>
    </v-btn>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="filters.date"
      title-date-format="ddd, D MMM YYYY"
      header-color="#FCF1EA"
      width="290px"
    >
      <v-date-picker
        event-color="secondary"
        locale="i18n.locale"
        color="#FBE8DC"
        v-model="date"
        scrollable
        selected-items-text="secondary"
        class="custom-date"
        :allowed-dates="allowedDates"
      >
        <v-spacer></v-spacer>
        <v-btn text color="brown" @click="modal = false">{{ $t("rundate.cancel") }}</v-btn>
        <v-btn text color="brown" @click="$refs.dialog.save(date)">{{ $t("rundate.ok") }}</v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Step0',
  data: () => ({
    date: null,
    modal: false
  }),
  props: ['filters', 'backdrop', 'runs'],
  computed: {
    availableDates () {
      let dates = [];
      let ngos = this.$store.getters.myNgos;
      const today = this.$moment().format('YYYY-MM-DD');
      if (ngos.length) {
        let nextAvailableDay = this.$moment();
        while (dates.length !== 4) {
          if (ngos[0].activeDow.includes(this.$moment(nextAvailableDay).day())) {
            dates.push({
              text: nextAvailableDay.format('YYYY-MM-DD') === today ? 'Today' : this.$d(nextAvailableDay, 'damo'),
              value: nextAvailableDay.format('YYYY-MM-DD')
            });
          }
          nextAvailableDay = nextAvailableDay.add(1, 'd');
        }
      }
      return dates;
    },
    currentDate () {
      if (this.filters.date) {
        return this.filters.date;
      }
      return this.$moment().format('YYYY-MM-DD');
    }
  },
  methods: {
    allowedDates (date) {
      return this.$store.state.auth.ngos[0].activeDow
        .includes(this.$moment(date).day()) && date >= this.$moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    currentDate () {
      this.date = this.currentDate;
    },
    availableDates: {
      handler () {
        if (this.availableDates.length) {
          this.filters.date = this.availableDates[0].value;
        }
      },
      deep: true
    }
  }
};
</script>

<template>
  <div class="step-layout full-height">
    <div class="add-top-layer">
      <v-progress-linear
        value="100"
        background-color="#F8DAC6"
        height="5"
        color="#93D4D6" />
      <div class="step-info">
        <div class="text">
          <h3 class="title">
            <span>{{ $t("confirm.confirm") }}</span>
            <span class="color">{{ $t("confirm.selection") }}</span>
          </h3>
        </div>
      </div>
      <div class="final-info">
        <div class="date-wrap">
          {{ $d($moment(filters.date), 'long') }}
        </div>
        <dropoff-point
          v-if="request.runs.length"
          :dropoff="selectedDrop"
          :dropoff-list="request.runs[0].dropofflist"
          :date="request.runs[0].date"
          @input="changeDropoff($event)"
        />
      </div>
    </div>
    <div class="selected-block">
      <div class="text">
        <p><strong>{{ $t("confirm.makesure") }}</strong> {{ $t("confirm.mistake") }}</p>
        <p><strong>{{ $t("confirm.ontime") }}</strong> {{ $t("confirm.late") }}</p>
      </div>
      <div class="selected-foods">
        <template v-for="run in request.runs">
          <run-card
            :removable="true"
            :light="true"
            :readOnly="true"
            @remove="remove"
            :key="run.id"
            :run="run"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RunCard from '../../components/run-card';
import DropoffPoint from '@/components/dropoff-point';

export default {
  name: 'Step5',
  props: ['request', 'filters', 'selectedDrop'],
  components: { RunCard, DropoffPoint },
  methods: {
    remove (item) {
      let index = this.request.runs.findIndex(run => run.id === item.id);
      this.request.runs.splice(index, 1);
    },
    changeDropoff (dropOff) {
      this.$emit('changeDropoff', dropOff);
    }
  }
};
</script>

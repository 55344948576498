<template>
  <div class="step-layout">
    <div class="add-top-layer">
      <v-progress-linear
        value="75"
        background-color="#F8DAC6"
        height="5"
        color="#93D4D6" />
      <div class="step-info">
        <div class="text">
          <h3 class="title">
            <span>{{ $t("dropselect.schedule") }}</span>
          </h3>
          <p class="sub-title"><strong>{{ $t("dropselect.location") }}</strong></p>
        </div>
      </div>
      <div class="ngo drop-offs">
        <v-row>
          <v-col cols="12">
            <div class="list-head mb-3">
              <div class="date">
                {{ $d($moment(filters.date), 'long') }}
              </div>
            </div>
            <drop-select
              :items="availableDropoffs"
              :selected-item="dropselection"
              @input="selectDrop($event)"
              :prefix="$t('dropoff.dropoff')"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>

import DropSelect from '@/components/drop-select.vue';

export default {
  name: 'Step4',
  components: {
    DropSelect
  },
  metaInfo: {
    title: 'Drop-off Selection'
  },
  data: () => ({
    backdrop: false,
    dropselection: null
  }),
  props: ['request', 'filters', 'selectedDrop'],
  computed: {
    onlyOneDropoff () {
      return Object.keys(this.request.runs[0].dropofflist).length === 1;
    },
    noDropoffSelected () {
      return Object.keys(this.selectedDrop).length === 0;
    },
    availableDropoffs () {
      return this.request.runs[0].dropofflist;
    }
  },
  mounted () {
    this.updateDrop();
  },
  methods: {
    updateDrop () {
      if (this.onlyOneDropoff) {
        this.setUniqueDropoff();
        this.nextStep();
      } else if (this.noDropoffSelected) {
        this.setDefaultDropoff();
      } else {
        this.setPreselectedDropoff();
        this.nextStep();
      }
    },
    setDefaultDropoff () {
      this.dropselection = this.request.runs[0].dropofflist[0].id;
      this.$emit('dropselection', this.dropselection);
    },
    setPreselectedDropoff () {
      this.dropselection = this.selectedDrop.id;
      this.$emit('dropselection', this.dropselection);
    },
    setUniqueDropoff () {
      this.selectedDrop = this.request.runs[0].dropoff;
      this.dropselection = this.request.runs[0].dropofflist[0].id;
      this.$emit('selectedDrop', this.selectedDrop);
      this.$emit('dropselection', this.dropselection);
    },
    selectDrop (drop) {
      this.dropselection = drop;
      this.$emit('dropselection', this.dropselection);
      this.backdrop = false;
    },
    nextStep () {
      this.$emit('next');
    }
  }
};
</script>

<template>
  <div v-if="currentStep === 0" class="home">
    <step-0 v-if="currentStep === 0" :request="request" :filters="filters"/>
    <slider :request="request" :filters="filters" />
    <div class="map-wrapper" v-if="currentStep !== 4">
      <LanguageSwitcher />
      <pieces />
      <google-map
        :request="request"
        :runs="availableRuns"
        @select-run="selectRun"
        :selectable="currentStep !== 1"
        ref="googleMap"
      />
    </div>
    <Footer v-if="currentStep === 0 && selectedRun.id" :selectedRun="selectedRun" @commit-run="jumpStep"></Footer>
    <Footer v-if="currentStep === 0 && !selectedRun.id" :selectedRun="selectedRun" @commit-run="nextStep"></Footer>
  </div>

  <div v-else class="add">
    <v-dialog
      v-model="error"
      max-width="415"
      content-class="warning-dialog"
    >
      <v-card>
        <v-card-title class="headline text-center">OOPS!</v-card-title>
        <v-card-text class="text-center">
          <div>
            {{ $t("index.something") }}
          </div>
          <div>
            {{ $t("index.please") }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="modal-btn ok"
            text
            @click="reset"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <navbar></navbar>
    <step-1
      v-if="currentStep === 1"
      :request="request"
      :filters="filters"
    />
    <step-2
      v-if="currentStep === 2"
      :request="request"
      :filters="filters"
    />
    <step-3
      v-if="currentStep === 3"
      :request="request"
      :filters="filters"
      @next="nextStep"
      @set-nearby-runs="setNearbyRuns"
    />
    <step-4
      v-if="currentStep === 4"
      :request="request"
      :filters="filters"
      @next="nextStep"
      :selectedDrop="selectedDrop"
      @selectedDrop="selectedDrop = $event"
      @dropselection="dropselection = $event"
    />
    <step-5
      v-if="currentStep === 5"
      :request="request"
      :filters="filters"
      :selectedDrop="selectedDrop"
      @changeDropoff="changeDropoff($event)"
    />

    <div class="map-wrapper" v-if="currentStep < 4">
      <pieces />
      <google-map
        :request="request"
        :runs="availableRuns"
        @select-run="selectRun"
        :selectable="currentStep !== 1"
        ref="googleMap"
      />
    </div>

    <div class="bottom-actions">
      <div class="btn-wrap">
        <v-btn
          v-if="isSelectedRunAdded() && currentStep === 3"
          @click="removeRun"
          rounded
          large
          color="primary"
          max-width="400"
          width="100%"
        >{{ $t("bottom.remove") }}</v-btn>
        <v-btn
          v-else-if="selectedRun.id && currentStep === 3"
          @click="addRun()"
          rounded
          large
          color="primary"
          max-width="400"
          width="100%"
        >{{ $t("bottom.this") }}</v-btn>
        <v-btn
          v-else-if="selectedRun.id && currentStep === 2"
          @click="addRun(true)"
          rounded
          large
          color="primary"
          max-width="400"
          width="100%"
        >{{ $t("bottom.add") }}</v-btn>
        <v-btn
          v-else-if="currentStep === 4"
          @click="selectDrop(dropselection)"
          rounded
          large
          max-width="400"
          width="100%"
          color="secondary"
        >{{ $t("bottom.select") }}</v-btn>
        <v-btn
          v-else-if="currentStep === 5"
          @click="confirm"
          rounded
          large
          max-width="400"
          width="100%"
          color="secondary"
        >{{ $t("bottom.confirm") }}</v-btn>
        <v-btn
          v-else-if="selectedRun.id || currentStep === 1 || currentStep === 3"
          @click="nextStep"
          rounded
          large
          color="primary"
          max-width="400"
          width="100%"
        > {{ $t("bottom.next") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pieces from '../../components/pieces';
import LanguageSwitcher from '../../components/language-switcher';
import GoogleMap from '../../components/google-map';
import runMixin from '@/mixins/run';
import Step0 from './step0';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import * as runService from '@/services/run';
import * as dropSelectionService from '@/services/dropselection';
import Footer from '../../components/footer-volunteer';
import Slider from '../../components/slider';
import Navbar from '../../components/navbar';

export default {
  name: 'home',
  timer: '',
  metaInfo: {
    title: 'Home'
  },
  components: { Pieces, LanguageSwitcher, Step0, Step1, Step2, Step3, Step4, Step5, GoogleMap, Footer, Slider, Navbar },
  data () {
    return {
      request: {
        runs: [],
        selected: {}
      },
      error: false,
      message: null,
      rundate: null,
      currentStep: 0,
      selectedDrop: null,
      dropselection: null,
      selectedRun: {
        id: null
      },
      filters: {
        date: null,
        minPickUp: null,
        maxPickUp: null
      },
      runs: [],
      nearbyRuns: [],
      backdrop: false
    };
  },
  mixins: [runMixin],
  methods: {
    getRuns () {
      runService.get(this.filters)
        .then(response => {
          this.runs = response;
        });
    },
    getDropSelection () {
      dropSelectionService.get(this.filters)
        .then(response => {
          this.selectedDrop = response[0];
        });
    },
    jumpStep () {
      this.request.runs.push(this.selectedRun);
      this.resetRun();
      this.currentStep = 3;
    },
    nextStep () {
      this.currentStep++;
    },
    addRun (next = false) {
      this.request.runs.push(this.selectedRun);
      this.resetRun();
      if (next) {
        this.nextStep();
      }
    },
    removeRun () {
      let index = this.request.runs.findIndex(run => run.id === this.selectedRun.id);
      this.request.runs.splice(index, 1);
      this.resetRun();
    },
    async confirm () {
      const promises = [];
      this.request.runs.map(async (run) => {
        promises.push(runService.claim(run.id));
      });
      await Promise.all(promises)
        .then((values) => {
          this.message = values;
          this.$store.commit('setAddingRuns', 0);
          this.$store.dispatch('loadRuns');
          this.$router.push('/runs');
        })
        .catch((error) => {
          this.message = error;
          this.error = true;
        });
    },
    reset () {
      this.$store.commit('setAddingRuns', 0);
      this.$store.dispatch('loadRuns');
      this.$router.push('/runs');
    },
    selectDrop (dropselection) {
      let params = {
        date: this.filters.date,
        dropoff_id: dropselection
      };
      dropSelectionService.select(params)
        .then(response => {
          this.selectedDrop = response[0];
        }).then(this.nextStep());
    },
    selectRun (item) {
      this.selectedRun = item;
      return this.request.runs.findIndex(run => run.id === item.id);
    },
    setNearbyRuns (runs) {
      if (!runs.length) {
        this.currentStep++;
      } else {
        this.nearbyRuns = [...this.request.runs, ...runs];
      }
    },
    isSelectedRunAdded () {
      return this.request.runs.find(run => run.id === this.selectedRun.id);
    },
    resetRun () {
      this.selectedRun = {};
      this.$refs.googleMap.selectedRun = {};
    },
    changeDropoff (dropoff) {
      this.selectedDrop = dropoff;
    }
  },
  created () {
    this.getRuns();
    this.timer = setInterval(this.getRuns, 60000);
  },
  mounted () {
    this.$bus.$on('prev-step', () => {
      if (this.currentStep === 0) {
        this.$store.commit('setAddingRuns', 0);
        this.$router.push('/');
      } else if (this.currentStep === 5) {
        this.currentStep = 2;
      } else {
        this.currentStep--;
      }
    });
    this.setDate();
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
  computed: {
    availableDates () {
      let dates = [];
      let ngos = this.$store.getters.myNgos;
      const today = this.$moment().format('YYYY-MM-DD');
      if (ngos.length) {
        let nextAvailableDay = this.$moment();
        while (dates.length !== 4) {
          if (ngos[0].activeDow.includes(this.$moment(nextAvailableDay).day())) {
            dates.push({
              text: nextAvailableDay.format('YYYY-MM-DD') === today ? 'Today' : nextAvailableDay.format('ddd, D MMM'),
              value: nextAvailableDay.format('YYYY-MM-DD')
            });
          }
          nextAvailableDay = nextAvailableDay.add(1, 'd');
        }
      }
      return dates;
    },
    availableRuns () {
      if (this.currentStep === 3) {
        return this.nearbyRuns;
      }
      return this.runs;
    }
  },
  watch: {
    filters: {
      handler () {
        this.getRuns();
        this.rundate = this.$moment(this.filters.date).format('YYYYMMDD');
        this.getDropSelection();
      },
      deep: true
    },
    availableDates: {
      handler () {
        if (this.availableDates.length) {
          this.filters.date = this.availableDates[0].value;
        }
      },
      deep: true
    }
  }
};
</script>

<template>
  <div class="step-layout">
    <div class="add-top-layer">
      <v-progress-linear
        value="75"
        background-color="#F8DAC6"
        height="5"
        color="#93D4D6" />
      <div class="step-info">
        <div class="text">
          <h3 class="title">
            <span>{{ $t("nearby.add") }}</span>
            <span class="color">{{ $t("nearby.nearby") }}</span>
          </h3>
          <p class="sub-title"><strong>{{ $t("nearby.there") }}{{ nearbyRuns.length }} {{ $t("nearby.nearby") }}</strong>{{ $t("nearby.similar") }}</p>
        </div>
      </div>
      <div class="top-actions" v-if="nearbyRuns.length">
        <v-btn class="btn-red" color="primary" rounded height="38" width="127" @click="addAll">{{ $t("nearby.yes") }}</v-btn>
        <v-btn class="btn-red" color="primary" rounded height="38" width="127" @click="next">{{ $t("nearby.no") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as runService from '@/services/run';

export default {
  name: 'Step3',
  data: () => ({
    nearbyRuns: []
  }),
  props: ['request', 'filters'],
  mounted () {
    this.getNearbyRuns();
  },
  methods: {
    getNearbyRuns () {
      runService.nearby(this.request.runs, this.filters)
        .then(response => {
          this.nearbyRuns = response;
          this.$emit('set-nearby-runs', this.nearbyRuns);
        });
    },
    addAll () {
      this.nearbyRuns.map(run => {
        this.request.runs.push(run);
      });
      this.next();
    },
    next () {
      this.$emit('next');
    }
  }
};
</script>
